<template>
    <div class="c-box">
        <div class="c-box__header">
            <h1 class="c-box__header__info">{{ getIcon() }} {{ visiblePanel }} Reservations</h1>
            <div class="c-box__header__actions">
                <div class="tabs is-toggle is-small">
                    <ul>
                        <li @click="changePanel('open')" v-bind:class="{ 'is-active': isPanelActive('open') }">
                            <a>
                                <span class="icon is-small">🧨</span>
                                <span>New Reservations <span class="tag is-danger is-small" v-if="NEWCOUNT > 0">{{ NEWCOUNT }}</span></span>
                            </a>
                        </li>
                        <li @click="changePanel('updated')" v-bind:class="{ 'is-active': isPanelActive('updated') }">
                            <a>
                                <span class="icon is-small">⚡</span>
                                <span>Updated Reservations <span class="tag is-danger is-small" v-if="UPDATEDCOUNT > 0">{{ UPDATEDCOUNT }}</span></span>
                            </a>
                        </li>
                      
                        <li @click="changePanel('all')" v-bind:class="{ 'is-active': isPanelActive('all') }">
                            <a>
                                <span>🛌</span>
                                <span>All reservations</span>
                            </a>
                        </li>
                        <li @click="changePanel('confirmed')" v-bind:class="{ 'is-active': isPanelActive('confirmed') }">
                            <a>
                                <span class="icon is-small">✅</span>
                                <span>Confirmed Reservations</span>
                            </a>
                        </li>


                    </ul>
                </div>
            </div>
        </div>

        <table class="table is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Guest</th>
                    <th>Check-in date</th>
                    <th>Check-out date</th>
                    <th>Nights paid by Booker</th>
                    <th>Nights paid by Guest</th>
                  
                    <th>Event</th>
                    <th class="is-centered is-small">Info</th>
                    <th class="is-centered is-small">Status</th>

                </tr>
            </thead>
            <tfoot>
                <tr>
                    <th>Guest</th>
                    <th>Check-in date</th>
                    <th>Check-out date</th>
                    <th>Nights paid by Booker</th>
                    <th>Nights paid by Guest</th>
                  
                    <th>Event</th>
                    <th class="is-centered is-small">Info</th>
                    <th class="is-centered is-small">Status</th>
                   

                </tr>
            </tfoot>

            <tbody>
                <tr v-for="(r, index) in paginated" :key="index"
                    @click="showReservation(r.links.find(l => l.method == 'GET').href)">
                    <td>{{ r.mainGuest.firstName }} {{ r.mainGuest.lastName }} </td>
                    <td>{{ $filters.humanDate(r.checkInDate) }}</td>
                    <td>{{ $filters.humanDate(r.checkOutDate) }}</td>
                    <td>{{ r.numberOfNightsPaidByBooker}}</td>
                    <td v-if="r.numberOfNightsPaidByGuest <= 0">0</td>
                    <td v-if="r.numberOfNightsPaidByGuest > 0">{{ r.numberOfNightsPaidByGuest}}</td>
                   
                    <td>{{ r.companyName}}</td>
                    <td class="is-centered is-small"><i v-if="r.extraInfo != null && r.extraInfo != ''" class="fa-solid fa-comment"></i></td>
                    <td class="is-centered is-small">
                        <span class="tag" :class="getStatusColor(r.status)">{{ r.status }}</span> 
                      
                       
                    </td>
                    

                </tr>
            </tbody>

        </table>
        <nav class="pagination" role="navigation" aria-label="pagination">
            <a :class="{ disabled: current == 1 }" @click="prev()"
                class="pagination-previous">Previous</a>
            <a :class="{ disabled: current*pageSize >= this.RESERVATIONS.length }" @click="next()"
                class="pagination-next">Next page</a>
        </nav>
        <div class="modal" v-bind:class="{ 'is-active': isReservationDetailsOpen }">
            <div class="modal-background"></div>
            <div class="modal-card" v-if="reservation">
                <header class="modal-card-head">
                    <p class="modal-card-title">⚡Reservation for {{ reservation.mainGuest.firstName }} {{reservation.mainGuest.lastName }}</p>
                    <button class="delete" aria-label="close" v-on:click="closeReservationModal"></button>
                </header>
                <section class="modal-card-body__info">
                    Reservation for '{{ reservation.companyName }}'
                </section>
                <section class="modal-card-body">

                    <div class="field">
                        <label class="label" for="firstname">Name</label>
                        <div class="control has-icons-left">
                            <input class="input" name="firstname"
                                :value="`${reservation.mainGuest.firstName} ${reservation.mainGuest.lastName}`" readonly>
                            <span class="icon is-small is-left">
                                🧑
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="checkIn">Check-in</label>
                        <div class="control has-icons-left">
                            <input class="input" name="checkIn" :value="`${$filters.humanDate(reservation.checkInDate)}`"
                                readonly>
                            <span class="icon is-small is-left">
                                ➡️
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="checkOut">Check-out</label>
                        <div class="control has-icons-left">
                            <input class="input" name="checkOut" :value="`${$filters.humanDate(reservation.checkOutDate)}`"
                                readonly>
                            <span class="icon is-small is-left">
                                ⬅️
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="numberOfRooms">Number of Rooms</label>
                        <div class="control has-icons-left">
                            <input class="input" name="numberOfRooms" :value="`${reservation.numberOfRooms}`" readonly>
                            <span class="icon is-small is-left">
                                🔢
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <label class="label" for="numberOfAdults">Number of Adults in rooms</label>
                        <div class="control has-icons-left">
                            <input class="input" name="numberOfRooms" :value="`${reservation.numberOfAdults}`" readonly>
                            <span class="icon is-small is-left">
                                👯
                            </span>
                        </div>
                    </div>

                    <div class="field" v-if="reservation.numberOfChildren > 0">
                        <label class="label" for="numberOfAdults">Number of Adults in rooms</label>
                        <div class="control has-icons-left">
                            <input class="input" name="numberOfRooms" :value="`${reservation.numberOfChildren}`" readonly>
                            <span class="icon is-small is-left">
                                👶
                            </span>
                        </div>

                    </div>
                    <div class="field" >
                        <label class="label" for="numberOfNightsByBooker">Number of nights covered by {{reservation.companyName}}</label>
                        <div class="control has-icons-left">
                            <input class="input" name="numberOfNightsByBooker" :value="`${reservation.numberOfNightsPaidByBooker}`" readonly>
                            <span class="icon is-small is-left">
                                💶
                            </span>
                        </div>

                    </div>
                    <div class="field">
                        <label class="label" for="numberOfNightsByBooker">Number of nights covered by {{ reservation.mainGuest.firstName }} {{ reservation.mainGuest.lastName }}</label>
                        <div class="control has-icons-left">
                            <input class="input" name="numberOfNightsByBooker" :value="`${reservation.numberOfNightsPaidByGuest}`" readonly>
                            <span class="icon is-small is-left">
                                💶
                            </span>
                        </div>

                    </div>
                    <div class="field" v-if="reservation.guests.length > 0">
                        <label class="label">Extra Guests</label>
                        <ul class="guests">
                            <li v-for="g in reservation.guests" :key="g.publicId">🙍 {{ g.firstName }} {{ g.lastName }}</li>
                        </ul>
                    </div>
                    <div class="field" v-if="reservation.extraInfo != null && reservation.extraInfo != ''" >
                        <label class="label">Extra Information</label>
                        <div class="control">
                            <textarea class="textarea" name="extraInformation" v-model="reservation.extraInfo" readonly ></textarea>
                           
                        </div>
                    </div>


                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success is-small" @click="confirmReservation">Confirm Reservation</button>
                    <!-- <button class="button is-danger is-small" @click="deleteReservation">Delete Reservation</button> -->
                    <button class="button is-small" v-on:click="closeReservationModal">Close</button>
                </footer>

            </div>

        </div>

    </div>
</template>
<script>
import { mapGetters } from "vuex";

import Axios from "axios";

export default {
    computed: {
        ...mapGetters({
            RESERVATIONS: "MyHotel/RESERVATIONS",
            NEWCOUNT: "MyHotel/NEWRESERVATIONSCOUNT",
            UPDATEDCOUNT: "MyHotel/UPDATEDRESERVATIONSCOUNT",
            PAGINATION: "MyHotel/PAGINATION",
        }),
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            let res = this.RESERVATIONS.slice(this.indexStart, this.indexEnd);
            return res;
        }
    }, data: function () {
        return {

            isReservationDetailsOpen: false,
            reservation: null,
            visiblePanel: 'open',
            current: 1,
            pageSize: 20,

        };
    },
    mounted: function () {


        this.getReservations();
      

    },
    methods: {
        prev() {
      this.current--;
    },
    getStatusColor(status){
        switch(status){
            case "Confirmed": return "is-info";
            case "Pending": return "is-danger";
            default: return "is-primary";
        }
    },
    next() {
      this.current++;
    },
        getReservations: function () {
            this.$store.dispatch("MyHotel/fetch_all_my_reservations", { hotelId: this.$route.params.hotelId, page: this.currentPage ? this.currentPage : 1 }).then(() => {
                this.$store.dispatch("MyHotel/filter_new_reservations");
            });
        },
        goToPage: function (cPage) {
            this.currentPage = cPage;
            this.$router.push({ name: 'mypublichotel', query: { page: cPage } });
            this.getReservations();
        },
        showReservation: function (lnk) {

            Axios.get(lnk)
                .then((response) => {
                    this.reservation = response.data.data.reservation;
                    this.reservationLinks = response.data.data.links;

                    this.openReservationModal();
                })
                .catch(() => {
                });
        }, closeReservationModal: function () {
            this.isReservationDetailsOpen = false;
        },
        openReservationModal: function () {
            this.isReservationDetailsOpen = true;
        },
        confirmReservation: function () {
            const lnk = this.reservationLinks.find(l => l.rel == 'confirm_reservation').href;
            Axios.put(lnk)
                .then((response) => {
                    this.RESERVATIONS.find(r => r.publicId == response.data.data.reservation.publicId).isConfirmed = true;
                    this.closeReservationModal();
                })
                .catch(() => {
                });

        },
        deleteReservation: function () {
            const lnk = this.reservationLinks.find(l => l.rel == 'delete_reservation').href;
            Axios.delete(lnk)
                .then(() => {
                    this.RESERVATIONS.splice(
                        this.RESERVATIONS.findIndex((item) => item.publicId === this.reservation.publicId),
                        1
                    );
                    this.reservation == null;
                    this.closeReservationModal();
                })
                .catch(() => {
                });
        },
        changePanel: function (panelName) {
            if (panelName == 'confirmed') this.filterConfirmedReservations(true);
            if (panelName == 'updated') this.filterUpdatedReservations();
            if (panelName == 'open') this.filterConfirmedReservations(false);
            if (panelName == 'all') this.removeFilterReservations(false);
            this.visiblePanel = panelName;
        },
        isPanelActive: function (panelName) {
            return this.visiblePanel == panelName;
        },
        getIcon: function () {
            switch (this.visiblePanel) {
                case "all": return '🛌';
                case "open": return '🧨';
                case "confirmed": return '✅';
                default: return '🛌';
            }
        },
        filterConfirmedReservations: function (isConfirmed) {
            this.current = 1;
            this.$store.dispatch("MyHotel/filter_reservations", isConfirmed);
        },
        filterOpenReservations: function () {
            this.current = 1;
            this.$store.dispatch("MyHotel/filter_new_reservations");
        },
        filterUpdatedReservations: function () {
            this.current = 1;
            this.$store.dispatch("MyHotel/filter_updated_reservations");
        },
        removeFilterReservations: function (isConfirmed) {
            this.current = 1;
            this.$store.dispatch("MyHotel/remove_filter_reservations", isConfirmed);
        }
    },


}
</script>