<template>
  <nav class="navbar" :class="{ 'navbar-mobile': isMobileNavbarVisible }" role="navigation" aria-label="main navigation" v-if="IAMSPEAKER">
    <div class="navbar-brand">
      <div class="event-logo-mobile">
        <a :href="this.EVENT.website" target="_blank">
          <img :src="this.EVENT.eventLogo" :title="this.EVENT.title" alt="logo" class="event-logo-mobile" />
        </a>
      </div>

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
        :class="{ 'is-active': isMobileNavbarVisible }" @click="showHideMobileNavBarMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': isMobileNavbarVisible }">
      <div class="navbar-start">
        <a class="navbar-item" v-bind:class="{ 'is-active': isPanelActive('sessions') }"
          @click="changePanel('sessions')">
          <span v-bind:class="{ 'is-active': isPanelActive('sessions') }">🎙️ </span><span>My Sessions</span>
        </a>
        <a v-if="IAMSPEAKER && IAMSPEAKER.isTravelRequired" class="navbar-item"
          v-bind:class="{ 'is-active': isPanelActive('travel') }" @click="changePanel('travel')">
          <span>✈️ </span><span>My Travel Info</span>
        </a>
        <a v-if="IAMSPEAKER && IAMSPEAKER.isHotelRequired" class="navbar-item"
          v-bind:class="{ 'is-active': isPanelActive('reservation') }" @click="changePanel('reservation')">
          <span>🛌 </span><span>My Hotel Reservation</span>
        </a>
        <a class="navbar-item" v-bind:class="{ 'is-active': isPanelActive('actions') }" @click="changePanel('actions')">
          <span>⚡ </span><span>My Actions</span>
        </a>

        <a class="navbar-item" v-bind:class="{ 'is-active': isPanelActive('speakerInfo') }"
          @click="changePanel('speakerInfo')">
          <span>🙋‍♂️ </span><span>Speaker info</span>
        </a>

        <a class="navbar-item" v-bind:class="{ 'is-active': isPanelActive('about') }" @click="changePanel('about')">
          <span>🎉 </span><span>About Techorama</span>
        </a>

      </div>


    </div>
  </nav>



  <header class="c-public-header public-page">
    <welcome-component></welcome-component>
    <a :href="this.EVENT.website" target="_blank">
      <img :src="this.EVENT.eventLogo" :title="this.EVENT.title" alt="logo" class="event-logo" />
    </a>

  </header>
  <loader v-if="ISLOADING" />
  <div>

    <main class="e-main">
      <div class="notification is-danger" @click="notificationClick"
        v-if="isNotificationOpen && ACTIONS.openActions && ACTIONS.openActions.length > 0">
        <button class="delete" @click.stop="closeNotification"></button>
        <span class="notification__title">⚡ Some work for you</span>
        <p class="notification__text">
          Please complete these actions before <strong>1 October</strong>.
        </p>


      </div>


      <div class="c-box c-box--public" v-if="IAMSPEAKER">

        <div class="c-box__header">
          <h1 class="c-box__header__info">{{ CURRENT_PAGE }}</h1>

          <div class="c-box__header__actions">
            <div class="tabs is-toggle is-small">
              <ul>
                <li @click="changePanel('sessions')" v-bind:class="{ 'is-active': isPanelActive('sessions') }">
                  <a>
                    <span v-bind:class="{ 'is-active': isPanelActive('sessions') }">🎙️</span>
                    <span>Sessions</span>
                  </a>
                </li>
                <li v-if="IAMSPEAKER && IAMSPEAKER.isTravelRequired" @click="changePanel('travel')"
                  v-bind:class="{ 'is-active': isPanelActive('travel') }">
                  <a>
                    <span class="icon is-small">✈️</span>
                    <span>Travel</span>
                  </a>
                </li>
                <li v-if="IAMSPEAKER && IAMSPEAKER.isHotelRequired"
                  v-bind:class="{ 'is-active': isPanelActive('reservation') }" @click="changePanel('reservation')">
                  <a>
                    <span class="icon is-small">🛌</span>
                    <span>Hotel</span>
                  </a>
                </li>
                <li class="c-actions-buttons" v-bind:class="{ 'is-active': isPanelActive('actions') }"
                  @click="changePanel('actions')">

                  <a>
                    <span class="icon is-small">⚡</span>
                    <span>Actions</span>
                  </a>

                </li>
                <li class="c-actions-buttons" v-bind:class="{ 'is-active': isPanelActive('speakerInfo') }"
                  @click="changePanel('speakerInfo')">

                  <a>
                    <span class="icon is-small">🙋‍♂️</span>
                    <span>Speaker info and schedule</span>
                  </a>

                </li>
                <li v-bind:class="{ 'is-active': isPanelActive('about') }" @click="changePanel('about')">
                  <a>
                    <span class="icon is-small">🎉</span>
                    <span>About Techorama</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="columns" v-if="IAMSPEAKER">
          <div class="column">
            <section v-if="isPanelActive('about')">

              <public-about-component></public-about-component>

            </section>
            <section v-if="isPanelActive('sessions')">

              <sessions-component></sessions-component>

            </section>

            <section v-if="isPanelActive('travel')">

              <travel-component></travel-component>

            </section>

            <section v-if="isPanelActive('reservation')">

              <hotel-reservation-component></hotel-reservation-component>

            </section>
            <section v-if="isPanelActive('speakerInfo')">

              <speaker-info-component></speaker-info-component>

            </section>

            <section v-if="isPanelActive('actions')">

              <actions-component></actions-component>

            </section>


          </div>


        </div>

      </div>

    </main>

  </div>
</template>
<script>

import WelcomeComponent from "@/components/WelcomePublicSpeakerComponent.vue";
import PublicAboutComponent from '@/components/public-speaker/AboutComponent.vue';
import SessionsComponent from '@/components/public-speaker/SessionsComponent.vue';
import TravelComponent from '@/components/public-speaker/TravelComponent.vue';
import HotelReservationComponent from '@/components/public-speaker/HotelReservationComponent.vue';
import SpeakerInfoComponent from '@/components/public-speaker/SpeakerInfoComponent.vue';
import ActionsComponent from '@/components/public-speaker/ActionsComponent.vue';
import Loader from '@/components/loaders/loaderComponent.vue';
// import TooltipComponent from '@/components/tooltips/TooltipComponent.vue';
import { mapGetters } from "vuex";

export default {
  name: "HotelsPage",
  components: {
    WelcomeComponent,
    PublicAboutComponent,
    SessionsComponent,
    TravelComponent,
    ActionsComponent,
    HotelReservationComponent,
    Loader,
    SpeakerInfoComponent
    // TooltipComponent
  },
  data: function () {
    return {
      eventSpeakerId: null,
      visiblePanel: 'about',
      isNotificationOpen: true,
      isMobileNavbarVisible: false
    }
  },
  computed: {
    ...mapGetters({
      IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
      ACTIONS: "IAmPublicSpeaker/ACTIONS",
      EVENT: "IAmPublicSpeaker/EVENT",
      ISLOADING: "Loader/IS_LOADING",
      CURRENT_PAGE: "IAmPublicSpeaker/CURRENT_PAGE",
    }),
  },
  mounted: async function () {
    this.eventSpeakerId = this.$route.params.eventSpeakerId;
    await this.$store.dispatch("IAmPublicSpeaker/fetch_iAmSpeaker", this.eventSpeakerId).then(()=>{
      if(this.ACTIONS && this.ACTIONS.openActions && this.ACTIONS.openActions.length > 0){
        this.changePanel('actions');
        this.$store.dispatch("IAmPublicSpeaker/update_current_page", `⚡ My Actions`)
      }
      else{
        this.$store.dispatch("IAmPublicSpeaker/update_current_page", `🎉 About Techorama`)
      }
    });
  
    
  },
  methods: {
    closeNotification: function () {
      this.isNotificationOpen = false;
    },
    changePanel: function (panelName) {
      switch (panelName) {
        case "travel": this.$store.dispatch("IAmPublicSpeaker/update_current_page", `✈️ My Travel Info`); break;
        case "reservation": this.$store.dispatch("IAmPublicSpeaker/update_current_page", `🛌 My Hotel Reservation`); break;
        case "actions": this.$store.dispatch("IAmPublicSpeaker/update_current_page", `⚡ My Actions`); break;
        case "sessions": this.$store.dispatch("IAmPublicSpeaker/update_current_page", `🎙️ My Sessions`); break;
        case "speakerInfo": this.$store.dispatch("IAmPublicSpeaker/update_current_page", `🙋‍♂️ Speaker info and schedule`); break;
        case "about":
        default: this.$store.dispatch("IAmPublicSpeaker/update_current_page", `🎉 About Techorama`); break;
      }
      this.isMobileNavbarVisible = false
      
      //this.showHideMobileNavBarMenu();
      /*
         <span class="icon is-small">🎉</span>
                      <span>About Techorama</span>
      
      */
      this.visiblePanel = panelName;

    },
    isPanelActive: function (panelName) {
      return this.visiblePanel == panelName;
    },
    notificationClick: function () {
      this.changePanel("actions");
      this.isNotificationOpen = false;
    },
    showHideMobileNavBarMenu: function () {
      this.isMobileNavbarVisible = !this.isMobileNavbarVisible;
    }
  }
};
</script>