<template>
  <header class="c-public-header public-page">
    <welcome-component></welcome-component>
    <img :src="this.EVENT.eventLogo" :title="this.EVENT.title" alt="logo" class="event-logo" />
  </header>
<div>
    <div class="breadcrumb">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><a href="#">Speakerflow</a></li>
          <li class="is-active">
            <a href="#">I am Partner: Template </a>
          </li>
        </ul>
      </nav>
    </div>
    <main class="e-main">
        fdsgfds
    </main>
</div>
</template>
<script>
import WelcomeComponent from "@/components/WelcomeComponent.vue";
import { mapGetters } from "vuex";
export default {
  name: "IAmPartnerPage",
  components: {
    WelcomeComponent
  },
  data: function () {
    return {
      eventPartnerId: null,
      visiblePanel: 'about',
      isNotificationOpen: true,
      isMobileNavbarVisible: false
    }
  },
  computed: {
    ...mapGetters({
      IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
      EVENT: "IAmPublicSpeaker/EVENT",
      ISLOADING: "IAmPublicSpeaker/IS_LOADING",
      CURRENT_PAGE: "IAmPublicSpeaker/CURRENT_PAGE",
    }),
  },
  mounted: function () {



  },
  methods: {

  }
};
</script>