import { createRouter as createVueRouter, createWebHistory } from "vue-router";

// import EventsPage from '@/pages/EventsPage';
// import EventPage from '@/pages/EventPage';
// import AdminPage from '@/pages/AdminPage';
// import TravelPage from '@/pages/TravelPage';
// import LoginPage from '@/pages/LoginPage';
// import ProfilePage from '@/pages/ProfilePage';


import MyPublicHotelPage from '@/pages/MyPublicHotelPage';
import IAmPartnerPage from '@/pages/IAmPartner';
import IAmPublicSpeakerPage from '@/pages/IAmPublicSpeaker';
import NotFound from '@/pages/PageNotFoundPage';



// import routesEvents from './routesEvents';
// import routesTravel from './routesTravel';
// import routesAdmin from './routesAdmin';


// import { createAuthGuard } from "@auth0/auth0-vue";

//import CallbackPage from '@/pages/CallbackPage';


//import IAmSpeakerPage from '@/pages/IAmSpeaker';


import StartPage from '@/pages/StartPage';


export default function createRouter() {
//export default function createRouter(app) {
  return createVueRouter({
    routes: [
      {
        path: '/',
        name:'start',
        component: StartPage
      },
      // {
      //   path: '/login',
      //   component: LoginPage
      // },
      // {
      //   path: '/profile',
      //   component: ProfilePage,
      //   beforeEnter: createAuthGuard(app)
      // },
      // {
      //   path: '/login',
      //   component: LoginPage
      // },
      // {
      //   path: '/admin',
      //   component: AdminPage,
      //   children: routesAdmin
      // },
      // {
      //   path: '/events/:eventname',
      //   component: EventPage,
      //   children: routesEvents,
      // },
     
      // {
      //   path: '/events',
      //   name: 'events',
      //   component: EventsPage
      // },
      // {
      //   path: '/hotels',
      //   name: 'hotelsPage',
      //   component: TravelPage,
      //   children: routesTravel,
      // },
      {
        path: '/my-hotel/:hotelId',
        name: 'mypublichotel',
        component: MyPublicHotelPage
      },
      {
        path: '/i-am-partner/:hotelId',
        name: 'publicpartner',
        component: IAmPartnerPage
      },
      // {
      //     path: '/i-am-speaker/:slug',
      //     name: 'mySpeakerPage',
      //     component: IAmSpeakerPage,
      //     children: routesIAmSpeaker
      // },
      {
        path: '/i-am-speaker/:eventSpeakerId',
        name: 'myPublicSpeakerPage',
        component: IAmPublicSpeakerPage
      },
      {
        path: "/:notFound",
        component: NotFound,
      },
     ],
    history: createWebHistory(process.env.BASE_URL)
  })
}