import Axios from "axios";

const initialState = () => ({
    flightInfo: {

    },
    hotelInfo: {
  
    }
});

const state = initialState();

const getters = {
    FLIGHT_INFO: (state) => {
        return state.flightInfo;
    
      },
      HOTEL_RESERVATION_INFO: (state) => {
        return state.hotelInfo;
    
      },
};

const actions = {
  // GET
  fetch_flight_info: async ({commit, dispatch}, payload) => {
    //console.log(payload);
    return Axios.get(payload)
      .then((response) => {
        commit("SET_FLIGHTINFO", response.data.data);
        dispatch("Loader/update_loading", false, { root: true });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_hotel_info: ({commit, dispatch}, payload) => {
    Axios.get(payload).then((response) => {
      commit("SET_RESERVATIONINFO", response.data.data);
      dispatch("Loader/update_loading", false, { root: true });
    });
  },
};

const mutations = {
  SET_FLIGHTINFO: (state, data) => {
    //console.log(state.flightInfo);
    state.flightInfo = data.flight;
    state.hotelInfo = data.hotel;
    state.isLoading = false;
  },
  SET_RESERVATIONINFO: (state, data) => {
    state.hotelInfo = data;
    state.isLoading = false;
  },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  
