import config from "../../config";
import Axios from "axios";


const initialState = () => ({
    crew_member: {
      
    },
    hotel_reservation: null,
});
const state = initialState();

const getters = {
    CREWMEMBER: (state) => {
        return state.crew_member;
    },
    HOTEL_RESERVATION: (state) =>{
      return state.hotel_reservation;
    }
}; 

const actions = {
  // GET
  fetch_crewMember: async (context, payload) => {
    console.log("speakers.store.js - GET speaker:" + payload);
    Axios.get(`${config.API_URL}events/${payload.eventId}/crew/${payload.crewmMemberId}`)
      .then((response) => {
        context.commit("SET_CREWMEMBER", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_crew_reservation: async (context, payload) => {
    // if(payload == null){
    //   context.commit("SET_SPEAKER_RESERVATION", null);
    // }
  
    Axios.get(payload)
      .then((response) => {
        context.commit("SET_CREW_HOTEL_RESERVATION", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  set_crew_hotel_reservation: async (context, payload) => {
    if(payload != null){
      context.commit("SET_CREW_HOTEL_RESERVATION", payload);
    }
  },
};

const mutations = {

  SET_CREWMEMBER: (state, data) => {
    state.crew_member = data;
  },
  SET_CREW_HOTEL_RESERVATION: (state, data) => {
    state.hotel_reservation = data;
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  