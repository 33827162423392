<template>
    <section class="c-loader">
        <progress class="progress is-small is-info" max="100">15%</progress>
    </section>
</template>
<script>

export default {
    props: ['text'],
}
</script>
<style>
.progress.is-small {
    height: 5px;
}

.c-loader{
    position: fixed;
    top: 5px;
   
   width: 100%;
}
</style>
