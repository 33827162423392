import { PublicClientApplication } from "@azure/msal-browser";
import { reactive } from "vue";



const config = {
    auth: {
        clientId: '8c95f8b3-6744-4b71-be85-6feb549de0d6',
        redirectUri: "http://localhost:8080/events",
        navigateToLoginRequestUrl:false,
        authority: 'https://login.microsoftonline.com/3fa86420-901f-472d-8012-2e9f3ad24850'
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
}

const data = reactive({
    account: null,
    msalInstance: new PublicClientApplication(config),
    token: ""
});

export function useAuth(){
    return data;
}