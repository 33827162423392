import config from "../../config";
import Axios from "axios";


const initialState = () => ({
  iAmSpeaker:null,
  event: {

  },
  links: [],
  sessions: {

  },
  hotelInfo: {

  },
  actions:{

  },
  currentPage: 'About Techorama'

});
const state = initialState();

const getters = {
  IAMSPEAKER: (state) => {
    return state.iAmSpeaker;
  },
  EVENT: (state) => {
    return state.event;
  },
  LINKS: (state) => {
    return state.links;
  },
  ACTIONS: (state) => {
    return state.actions;
  },
  SESSIONS: (state) => {
    return state.sessions;
  },
  CURRENT_PAGE: (state) => {
    return state.currentPage;

  },


};

const actions = {
  fetch_iAmSpeaker: ({commit, dispatch}, payload) => {
    // console.log("> store: getting i am public speaker");
    return Axios.get(`${config.API_URL}IAmSpeaker/${payload}`)
      .then((response) => {
        commit("SET_IAMSPEAKER", response.data.data);
        dispatch("Loader/update_loading", false, { root: true });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_sessions: ({commit, dispatch}, payload) => {
    Axios.get(payload).then((response) => {
      commit("SET_SESSIONS", response.data.data);
      dispatch("Loader/update_loading", false, { root: true });
    });
  },
  fetch_actions: (context, payload) => {
    // console.log(payload);
    Axios.get(payload).then((response) => {
      context.commit("SET_ACTIONS", response.data.data);
    });
  },
  complete_action: (context, payload) => {
    // console.log(payload);
  
      context.commit("COMPLETE_ACTION",payload);

  },
    update_action: (context, payload) => {
    // console.log(payload);
  
      context.commit("UPDATE_ACTION",payload);

  },


  update_current_page: (context, payload) => {
    context.commit("SET_CURRENT_PAGE",payload);

  },
};

const mutations = {
  SET_IAMSPEAKER: (state, data) => {
    state.iAmSpeaker = data.speaker;
    state.event = data.event;
    state.links = data.links;
    state.actions = data.actions;
   
    if(data.actions && data.actions.openActions && data.actions.openActions.length > 0) {
      state.currentPage = 'actions';
    }
  
  },
  SET_EVENT_CACHE: (state, data) => {
    state.event = data.data;
  },
  SET_SESSIONS: (state, data) => {
    state.sessions = data.sessions;
  },
  SET_ACTIONS: (state, data) => {
    state.actions = data;
  },
  SET_RESERVATIONINFO: (state, data) => {
    state.hotelInfo = data;
  },
  COMPLETE_ACTION: (state, data) => {
    const objWithIdIndex = state.actions.openActions.findIndex((obj) => obj.actionId === data.actionId);
    if (objWithIdIndex > -1) {
    
      
      state.actions.openActions.splice(objWithIdIndex, 1);
      state.actions.completedActions.push(data);
      }
 },
 UPDATE_ACTION: (state, data) => {
    const objWithIdIndex = state.actions.completedActions.findIndex((obj) => obj.actionId === data.actionId);
    
    if (objWithIdIndex > -1) {
      state.actions.completedActions.splice(objWithIdIndex, 1);
      state.actions.completedActions.push(data);
      }
 },
  SET_CURRENT_PAGE: (state, title) => {
    state.currentPage = title;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

