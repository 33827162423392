<template>
  <div class="c-app c-light">
   
    <router-view></router-view>
    <footer class="c-footer">
      <span class="footer-text">Made with ❤️ by <span class="logo">&gt;</span> <span
          class="logo-extension">SpeakerFlow</span></span>
    </footer>
  </div>
</template>

<script>


export default {
  name: "App",
  components: {

  },
};
</script>
<style>
#app {
  height: 100%;
}

.c-footer {
  position: relative;
}

.logo {
  background-color: #3273dc;
  color: #FFF;
  display: inline-block;
  padding: 2px 5px;
  margin-right: 3px;
}

.logo-extension {
  position: absolute;
  display: none;

  top: 1px;
}

.footer-text:hover .logo-extension {
  cursor: pointer;
  display: inline-block;
}

.navbar{
  display: block !important;
  position: absolute;
  right: 20px;
  top: 5px;
}
</style>
