import config from "../../config";
import Axios from "axios";


const initialState = () => ({
  current_hotel: {
      hotel: {},
      links: []
    }
});
const state = initialState();

const getters = {
    HOTEL: (state) => {
        return state.current_hotel.hotel;
    }
}; 

const actions = {
  set_current_hotel: (context, payload)=>{
    context.commit("SET_HOTEL_CACHE", payload);
  },
  fetch_my_hotel: (context)=>{
    return Axios.get(`${config.TRAVEL_URL}hotels/${config.CURRENT_HOTEL}`)
      .then((response) => {
        context.commit("SET_HOTEL", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_hotel: (context, payload)=>{
    return Axios.get(`${payload}`)
      .then((response) => {
        context.commit("SET_HOTEL", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
   // GET
   fetch_hotels: async (context, payload) => {
    console.log("hotels.store.js - GET hotels:" + payload);
    return Axios.get(`${config.API_URL}events/${payload.eventId}/hotels?pageNumber=${payload.page}`)
      .then((response) => {
        context.commit("SET_HOTELS", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  };

const mutations = {
  SET_HOTEL_CACHE: (state, data) => {
    state.current_hotel.hotel = data;
  },
  SET_HOTEL: (state, data) => {
    console.log("event details");
    state.current_hotel.hotel = data.data;
    if(data.data.links) state.current_hotel.links = data.data.links;
  },
     // SET
  SET_HOTELS: (state, data) => {
    state.hotels.all = data.hotels;
    state.pages = data.pages;
  },
  // ADD_HOTEL: (state, data) => {
  //   console.log("new hotel");
  //   state.hotels.all.push(data);
  // }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  