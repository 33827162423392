<template>
  <div class="c-welcome" v-if="IAMSPEAKER">
    <h1 class="c-welcome__person">{{ FriendlyText }}, {{ IAMSPEAKER.firstName }}!</h1>
    <span class="c-welcome__description">Your information and <strong>actions</strong> for {{ EVENT.title }}</span>
  </div>
  <div class="c-welcome" v-if="!IAMSPEAKER">
    <h1 class="c-welcome__person">{{ FriendlyText }},</h1>
    <span class="c-welcome__description">please hold on while we are getting your speaker information!</span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
      EVENT: "IAmPublicSpeaker/EVENT"
    }),
    FriendlyText() {
      var myDate = new Date();
      var hrs = myDate.getHours();

      var friendlyText;

      if (hrs < 12)
        friendlyText = 'Good Morning';
      else if (hrs >= 12 && hrs <= 17)
        friendlyText = 'Good Afternoon';
      else if (hrs >= 17 && hrs <= 24)
        friendlyText = 'Good Evening';

      return friendlyText;
    }
  },
}

</script>