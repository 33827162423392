import config from "../../config";
import Axios from "axios";


const initialState = () => ({

  hotel: {},
  links: [],
  reservations: [],
  allReservations: [],
  newCount: 0,
  updateCount: 0,
  pages: {
    currentPage: 1,
    totalPages: 1,
    totalCount: 3,
    nextPage: null,
    previousPage: null,
    hasPrevious: null,
    hasNext: false
  },
  paginate: (page = 1, perPage = 10) => {
    const offset = perPage * (page - 1);
    const totalPages = Math.ceil(this.reservations.length / perPage);
    const paginatedItems = this.reservations.slice(offset, perPage * page);

    return {
      previousPage: page - 1 ? page - 1 : null,
      nextPage: (totalPages > page) ? page + 1 : null,
      total: this.reservations.length,
      totalPages: totalPages,
      items: paginatedItems
    };
  }

});
const state = initialState();

const getters = {
  HOTEL: (state) => {
    //console.log(state.current_hotel.hotel);
    return state.current_hotel.hotel;
  },
  RESERVATIONS: (state) => {
    //console.log(state.reservations);
    return state.reservations;
  },
  ALLRESERVATIONS: (state) => {

    return state.allReservations;
  },
  NEWRESERVATIONSCOUNT: (state) => {

    return state.newCount;
  },
  UPDATEDRESERVATIONSCOUNT: (state) => {

    return state.updateCount;
  },
  PAGINATION: (state) => {
    return state.pages;
  },
};

const actions = {
  fetch_my_reservations: (context, payload) => {
    //console.log("getting reservations");
    return Axios.get(`${config.TRAVEL_URL}hotels/${payload.hotelId}/reservations?pageNumber=${payload.page}`)
      .then((response) => {
        //console.log(response);
        context.commit("SET_RESERVATIONS", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_all_my_reservations: (context, payload) => {
    return Axios.get(`${config.TRAVEL_URL}hotels/${payload.hotelId}/reservations/all`)
      .then((response) => {
        //console.log(response);
        context.commit("SET_ALL_RESERVATIONS", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  filter_reservations: (context, payload) => {
    let reservations = context.getters.ALLRESERVATIONS.filter(r => r.isConfirmed == payload && r.isUpdated == false);
    context.commit("SET_RESERVATIONS", reservations);
    console.log(reservations);
  },
  filter_new_reservations: (context) => {
    let reservations = context.getters.ALLRESERVATIONS.filter(r => r.isConfirmed == false && r.isUpdated == false);
    context.commit("SET_RESERVATIONS", reservations);
    console.log(reservations);
  },
  filter_updated_reservations: (context) => {
    let reservations = context.getters.ALLRESERVATIONS.filter(r => r.isUpdated == true);
    context.commit("SET_RESERVATIONS", reservations);
    console.log(reservations);
  },
  remove_filter_reservations: (context) => {
    context.commit("REMOVE_ALL_RESERVATIONS");

  }
};

const mutations = {
  SET_HOTEL: (state, data) => {
    console.log("event details");
    state.current_hotel.hotel = data.data;
    if (data.data.links) state.current_hotel.links = data.data.links;
  },
  SET_RESERVATIONS: (state, data) => {
    // let newCount = 0;
    // let updateCount = 0;
    // for(let i = data.reservations.length ; i > 0 ; i--){
    //   let o = data.reservations[0];
    //   if(o.isConfirmed == false && o.isUpdated == false) newCount++;
    //   if(o.isUpdated == true) updateCount++;
    // }
    state.reservations = data;
    // state.newCount = newCount;
    // state.updateCount = updateCount;
  },
  REMOVE_ALL_RESERVATIONS: (state) => {
    console.log("reservations");
    state.reservations = state.allReservations;
  },
  SET_ALL_RESERVATIONS: (state, data) => {

    state.allReservations = data.data.reservations;
    state.reservations = data.data.reservations;
    state.pages = data.data.pages;

    let newCount = 0;
    let updateCount = 0;

    for (let i = state.allReservations.length; i > 0; i--) {
      let o = state.allReservations[i - 1];

      if (o.isConfirmed == false && o.isUpdated == false) {
        newCount++;
      }

      if (o.isUpdated == true) {
        updateCount++;
      }
    }
    state.newCount = newCount;
    state.updateCount = updateCount;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

