import { createApp } from 'vue';
import createRouter from './routes/router';
import store from "./stores/index.js";
import Emitter from 'tiny-emitter';
import App from './App.vue';
import dayjs from 'dayjs'
import "./scss/main.scss";
import objectPlugins from './plugins/objectPlugins';
//const app = createApp(App).use(router).use(store).mount('#app');


const app = createApp(App);

app.config.globalProperties.$filters = {
    humanDate(date) {
        if (!date) {
            return null;
        }

        return dayjs(date).format('DD/MM/YYYY');
    },
    localDate(date) {
        if (!date) {
            return null;
        }
        date = new Date(date);
        var localDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
         );
        return dayjs(localDate).format('DD/MM/YYYY');
    },
    humanDateandHour(date) {
        if (!date) {
            return null;
        }
       
        return dayjs(date).format('DD/MM/YYYY h:mm A');
    },


};

app.config.globalProperties.$emitter = new Emitter();



app.use(createRouter(app));
app.use(store);
app.use(objectPlugins);


// app.use(
//     Auth0Plugin({
//       domain: domain,
//       clientId: clientId,
//         authorizationParams: {
//         redirect_uri:window.location.origin,
//         audience: audience,
//       }
//     })
//   );

app.mount('#app');
