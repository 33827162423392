<template>
    <div class="content-container">
      <figure>
                    <img src="@/assets/logo_blue.png" title="speakerflow" alt="speakerflow logo">
               
                </figure>
     <blockquote>
      <p>Oops! Looks like you've stumbled upon a page that's gone on a wild adventure, exploring the vast wilderness of the internet.</p><p> We hope it's having a good time out there, but in the meantime, why don't you try searching for what you were looking for? Who knows, maybe you'll stumble upon it too!</p>
     </blockquote>
    </div>
  </template>
  <style>
    .content-container{
      
      font-size: 20px;
      width: 400px;
      margin: 20px auto;
    }

    .content-container img{
      width: 60%;
      position: relative;
      left: -48px;
    }

    .content-container p{
      margin-bottom: 20px;
    }


</style>