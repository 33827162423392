<template>
    <section class="panel is-danger" v-if="ISLOADING == false && (FLIGHT_INFO == null || FLIGHT_INFO.flight == nul)">
        <h2 class="panel-heading">✈️ My travel info</h2>
        <div class="panel-block">
            <div class="panel-block__header">
                <h3><strong>No Flights</strong> has been booked for you
                </h3>
            </div>
            <div class="panel-block__warning"></div>
            <p>
                No reservation has been made for you. This could be because:
            <ul>
                <li>You live in the neighbourhood of the eventlocation</li>
                <li>You told us you don't need a hotelreservation</li>
                <li>You booked a hotel yourself</li>
            </ul>
            </p>
            <p>
                If we made a mistake, sorry but we will fix it for you.
                Please contact us via <a href="mailto:board@techorama.nl">board@techorama.nl</a></p>

        </div>
    </section>
    <section class="panel" v-if="FLIGHT_INFO != null && FLIGHT_INFO.flight != null && FLIGHT_INFO.flight.flightSegments != null">
        <h2 class="panel-heading">✈️ Flight information</h2>
        <div class="panel-block" >
            <FlightsComponent></FlightsComponent>
        </div>
    </section>
</template>
<script>
import { mapGetters } from "vuex";
import FlightsComponent from "@/components/travel/FlightsComponent.vue";


export default {
    components: {
        FlightsComponent
    },
    computed: {
        ...mapGetters({
            IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
            LINKS: "IAmPublicSpeaker/LINKS",
            FLIGHT_INFO: "Travel/FLIGHT_INFO",
            ACTIONS: "IAmPublicSpeaker/ACTIONS",
            ISLOADING: "Loader/IS_LOADING",
        }),
    },
    mounted: function () {
        this.getTravelInfo();
    },
    methods: {
        getTravelInfo: function () {
            //console.log("getting flight info");
            if (this.isObjectEmpty(this.FLIGHT_INFO)) {
                this.$store.dispatch("Loader/update_loading", true);
                const link = this.LINKS.find(l => l.rel == 'travel-info').href
                this.$store.dispatch("Travel/fetch_flight_info", link);
                this.$store.dispatch("Loader/update_loading", false);
            }


        },
    },
}
</script>
