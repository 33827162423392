<template>
   <div class="info-card" v-if="FLIGHT_INFO != null && FLIGHT_INFO.flight != null">
                <div class="flight__segments" v-if="FLIGHT_INFO.flight.flightSegments">
                    <div class="flight__segment" v-for="segment in FLIGHT_INFO.flight.flightSegments"
                        :key="segment.flightNumber">
                        <div class="flight__trip">
                            <span class="flight__date">{{ $filters.humanDateandHour(segment.departureDate) }}</span>
                            <span class="flight__airport">{{ segment.departureCity }}</span>
                        </div>
                        <div class="flight-arrow">
                            ➡️
                        </div>
                        <div class="flight__trip flight__trip--right">
                            <span class="flight__date">{{ $filters.humanDateandHour(segment.arrivalDate) }}</span>

                            <span class="flight__airport">{{ segment.arrivalCity }}</span>
                        </div>
                    </div>
                </div>
            </div>
       

</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            FLIGHT_INFO: "Travel/FLIGHT_INFO"
        }),
    },
}
</script>

<style lang="scss">
@import '../../scss/theme/t.light';


:root {
    --segment-width: 100%;
}

.flight__segments {
    display: flex;
    flex-direction: column;
    width: 100%;

    .flight__segment {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        width: var(--segment-width);
    }
}


.flight__segment+.flight__segment {
    border-top: 1px solid $line-color;
    padding-top: 10px;
}

.flight__date {
    display: inline-block;
    font-weight: 600;
    margin-right: 10px;

}

.flight-arrow {
    text-align: center;

}

.flight__trip {
    margin: 0px 5px;
    display: flex;
    flex-direction: column;
    width: calc((var(--segment-width) / 3)*3);

    .flight__city {
        color: $header-color;
        text-transform: uppercase;
        font-size: .7em;
        margin-left: 28px;
    }

    &.flight__trip--right {
        justify-content: flex-end;
        align-items: flex-end;
    }

}


@media only screen and (max-width: 600px) {

    .info-card.info-card__flight-info{
        padding: 0;
    }
    .flight__segments {
        .flight__trip {
            font-size: 12px;
            flex-direction: column;
            margin: 0px;

            .flight__date {
                display: block;
                font-weight: 600;
                margin-right: 5px;

            }

            .flight__airport {
                display: block;
            }

            &.flight__trip--right {

                align-items: flex-end;
            }

        }
    }


}
</style>