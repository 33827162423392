<template>
  <section class="panel" v-if="ACTIONS && ACTIONS.openActions && ACTIONS.openActions.length > 0">
    <div class="panel-header">
      <h2 class="panel-heading">⚡ Open actions</h2>
    </div>
    <div class="panel-description">
      Please complete these actions.
    </div>
    <div class="panel-block">
      <div class="actions">

        <action-component v-for="a in ACTIONS.openActions" :action-id="a.id" :action-title="a.title"
          :action-description="a.description" :action-status="a.status" :action-priority="a.priority" :key="a.id"
          v-on:click="showDetails(a.actionId, false, true)"></action-component>
      </div>

    </div>
  </section>
  <section class="panel" v-if="ACTIONS && ACTIONS.stickyActions && ACTIONS.stickyActions.length > 0">
    <div class="panel-header">
      <h2 class="panel-heading">📌 Sticky actions</h2>
    </div>
    <div class="panel-description">
      Please complete these actions.
    </div>
    <div class="panel-block">
      <div class="actions">
        <action-component v-for="a in ACTIONS.stickyActions" :action-id="a.id" :action-title="a.title"
          :action-is-sticky="a.isSticky" :action-description="a.description" :action-status="a.status"
          :action-priority="a.priority" :key="a.id"
          v-on:click="showDetails(a.actionId, true, completedAt)"></action-component>

      </div>

    </div>
  </section>

  <section class="panel" v-if="ACTIONS && ACTIONS.completedActions && ACTIONS.completedActions.length > 0">
    <div class="panel-header" @click="showClosedFinishedActions">
      <h2 class="panel-heading">⚡ Finished actions</h2>
      <div class="panel-actions">
        <button class="button is-small is-ghost">
          <i class="fas fa-chevron-down" v-if="finishedactionsvisible"></i>
          <i class="fa-sharp fa-solid fa-chevron-up" v-if="!finishedactionsvisible"></i>
        </button>
      </div>

    </div>
    <div class="panel-description" v-if="finishedactionsvisible">
      These actions are already answered, you can still change them if you really really need to ;-)
    </div>
    <div class="panel-block" v-if="finishedactionsvisible">
      <div class="actions">
        <action-component v-for="a in ACTIONS.completedActions" :action-id="a.id" :action-title="a.title"
          :action-description="a.description" :action-status="a.status" :action-priority="a.priority" :key="a.id"
          v-on:click="showDetails(a.actionId, false, completedAt)"></action-component>
      </div>

    </div>
  </section>
  <div class="modal" v-bind:class="{ 'is-active': isActionDetailsModalOpen }">
    <div class="modal-background"></div>
    <div class="modal-card" v-if="selectedAction != null">
      <header class="modal-card-head">
        <p class="modal-card-title">⚡ {{ selectedAction.title }}</p>
        <button class="delete" aria-label="close" v-on:click="closeActionDetailsModal"></button>
      </header>
      <div class="modal-card-body__info" v-if="completedText">
        {{ completedText }}
      </div>
      <section class="modal-card-body">

        <div class="action__text">
          <div v-html="selectedAction.text"></div>
          <hr class="divider" />
        </div>

        <div class="action__body">
          <div v-if="selectedAction.type == 'TRAVELOK'">
            <div v-if="FLIGHT_INFO == null" class="action-info">
              <p class="action-info__title">No flight has been booked for you!</p>
              <p class="action-info__description">
                Speakers should have received a link to book travel. If you didn't receive the link but you need a flight,
                let us know.<br /><br />
                If you decided to book the flight yourself, than everything is set.
              </p>
            </div>
            <div v-if="FLIGHT_INFO != null && FLIGHT_INFO.flight != null && FLIGHT_INFO.flight.flightSegments != null" class="action-info">
              <h2 class="action-info__title">Flight information</h2>
                <FlightsComponent></FlightsComponent>
              </div>
          </div>
          <div v-if="selectedAction.type == 'HOTELOK'">
            <div v-if="HOTEL_RESERVATION != null && HOTEL_RESERVATION.reservationId" class="action-info">
              <p class="action-info__title">Your reservation @ {{ HOTEL_RESERVATION.hotel.name }}</p>
              <p class="action-info__description"> {{ HOTEL_RESERVATION.hotel.address }}, {{ HOTEL_RESERVATION.hotel.city
              }},
                {{ HOTEL_RESERVATION.hotel.country }}</p>

              <div class="action-info__body">
                <div class="time-in-time-out">
                  <div>
                    <strong>➡️ check-in date: </strong>
                    <span>{{ $filters.humanDate(HOTEL_RESERVATION.checkInDate) }}</span>
                  </div>
                  <div>
                    <strong>⬅️ check-out date: </strong>
                    <span>{{ $filters.humanDate(HOTEL_RESERVATION.checkOutDate) }}</span>
                  </div>
                </div>

                <div>
                  <strong>🔢 rooms: </strong>
                  <span>{{ HOTEL_RESERVATION.numberOfRooms }}</span>
                </div>
                <div>
                  <strong>🔢 guests: </strong>
                  <span>{{ HOTEL_RESERVATION.numberOfAdults }} adult(s), {{ HOTEL_RESERVATION.numberOfChildren }}
                    children</span>
                </div>
              </div>

            </div>

          </div>
          <div v-if="selectedAction.type == 'LINK'" class="c-action__link">
            <label>Download link:</label>
            <span><a :href="selectedAction.link" class="c-action__link__href" target="_blank">{{ selectedAction.link
            }}</a></span>
          </div>
          <div class="action-info__response" v-if="selectedAction.type != 'LINK'">
            <label>Extra comments. Use this field when you want to add something to your response!</label>
            <textarea class="textarea" placeholder="extra info" v-model="response"></textarea>
          </div>
        </div>

      </section>

      <footer class="modal-card-foot">
        <button class="button is-success " v-if="selectedAction.type == 'READ'"><i class="fas fa-check"></i> &nbsp; I have
          read the message</button>
        <button class="button is-success " v-if="selectedAction.type == 'YESNO'"
          @click="completeAction(selectedAction.actionId, true)"><i class="fas fa-check"></i> &nbsp;
          YES</button>
        <button class="button is-danger " v-if="selectedAction.type == 'YESNO'"
          @click="completeAction(selectedAction.actionId, false)"><i class="fas fa-ban"></i> &nbsp;
          NO</button>
        <button class="button is-success " v-if="selectedAction.type == 'HOTELOK' || selectedAction.type == 'TRAVELOK'"
          @click="completeAction(selectedAction.actionId, true)"><i class="fas fa-check"></i> &nbsp;
          OK</button>
        <button class="button is-danger " v-if="selectedAction.type == 'HOTELOK' || selectedAction.type == 'TRAVELOK'"
          @click="completeAction(selectedAction.actionId, false)"><i class="fas fa-ban"></i> &nbsp;
          NOT OK</button>
        <button class="button " v-on:click="closeActionDetailsModal">Close</button>
      </footer>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ActionComponent from './ActionComponent.vue';
import FlightsComponent from "@/components/travel/FlightsComponent.vue";
import Axios from "axios";
export default {
  computed: {
    ...mapGetters({
      ACTIONS: "IAmPublicSpeaker/ACTIONS",
      IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
      HOTEL_RESERVATION: "Travel/HOTEL_RESERVATION_INFO",
      LINKS: "IAmPublicSpeaker/LINKS",
      FLIGHT_INFO: "Travel/FLIGHT_INFO",
    }),
  },
  data: function () {
    return {
      isActionDetailsModalOpen: false,
      selectedAction: null,
      selectedType: null,
      response: null,
      finishedactionsvisible: false,
      completedText: null
    }
  },
  components: {
    ActionComponent,
    FlightsComponent
  },
  mounted: function () {
    if (this.isObjectEmpty(this.ACTIONS)) {
      this.getActions();
    }


  },
  methods: {
    getActions: function () {
      const link = this.LINKS.find(l => l.rel == 'actions').href
      this.$store.dispatch("IAmPublicSpeaker/fetch_actions", link);
    },
    showDetails: function (id, isSticky, isOpen) {
    if (isSticky) {
        this.selectedAction = this.ACTIONS.stickyActions.find(ac => ac.actionId == id);
      }
      else {
        if (isOpen) {
          this.selectedAction = this.ACTIONS.openActions.find(ac => ac.actionId == id);
        }
        else {
          this.selectedAction = this.ACTIONS.completedActions.find(ac => ac.actionId == id);
        }

      }


      if (!isOpen) {
        if (!isSticky) {
          const d = this.$filters.localDate(this.selectedAction.completedAt);
          this.completedText = `You completed this action on ${d} responding ${this.selectedAction.yesNo ? "YES" : "NO"}`;
          this.response = this.selectedAction.response
        }

       

        if (this.selectedAction.type == "YESNO") {

          // const link = this.selectedAction.links.find(l => l.rel == 'complete_action').href
          // console.log("> " + link)



        }



      }


      if (this.selectedAction.type == "TRAVELOK") {
          if (this.isObjectEmpty(this.FLIGHT_INFO)) {
            const link = this.LINKS.find(l => l.rel == 'travel-info').href
            this.$store.dispatch("Travel/fetch_flight_info", link);
          }
        }

        if (this.selectedAction.type == "HOTELOK") {
          if (this.isObjectEmpty(this.HOTEL_RESERVATION)) {
            const link = this.LINKS.find(l => l.rel == 'hotel-info').href
            this.$store.dispatch("Travel/fetch_hotel_info", link);
          }

        }

      this.showActionDetailsModal();

    },
    closeActionDetailsModal: function () {
      this.isActionDetailsModalOpen = false;
      this.selectedAction == null;
      this.completedText = null;
      this.response = null;
    }
    ,
    showActionDetailsModal: function () {
      this.isActionDetailsModalOpen = true;
    }
    ,
    completeAction(actionId, isOk) {

      const link = this.selectedAction.links.find(l => l.rel == 'complete_action').href;
      if (this.selectedAction.status && this.selectedAction.status == 'COMPLETED') {
        Axios.put(link, {
          response: {
            isOk: isOk,
            message: this.response
          }
        }).then((response) => {

          this.$store.dispatch("IAmPublicSpeaker/update_action", response.data.data);
          this.closeActionDetailsModal();

        });
      }
      else {
        Axios.post(link, {
          response: {
            isOk: isOk,
            message: this.response
          }
        }).then((response) => {

          this.$store.dispatch("IAmPublicSpeaker/complete_action", response.data.data);
          this.closeActionDetailsModal();

        });
      }

    },
    showClosedFinishedActions() {
      this.finishedactionsvisible = !this.finishedactionsvisible;
    
    }
  },
}

</script>
