<template>
  <div class="sessions">
    <div class="sessions__session" v-for="s in SESSIONS" :key="s.id">
      <div class="session__nrOfPeople" v-if="s.speakers.length > 1">
        <span class="tag is-warning">{{ s.speakers.length }}&nbsp;<i class="fa-solid fa-person"></i></span>
      </div>
      <div class="card">
        <div class="card-content">
          <p class="session__title">{{ s.title }}</p>

          <p v-html="s.description" class="session__description"></p>
          <div class="card__extras">
            <div class=card__extra><span class="card__extra__title">Room:</span> {{ s.room }}</div>
            <div class=card__extra><span class="card__extra__title">Starts at:</span>
              {{ $filters.humanDateandHour(s.startsAt) }}</div>
            <div class=card__extra><span class="card__extra__title">Ends at:</span>
              {{ $filters.humanDateandHour(s.endsAt) }}</div>
          </div>
          <div class="card__tags">
            <span class="tag is-primary">{{ s.type }}</span>
            <span class="tag is-warning">{{ s.level }}</span>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
      LINKS: "IAmPublicSpeaker/LINKS",
      SESSIONS: "IAmPublicSpeaker/SESSIONS",
    }),
  },
  mounted: function () {


    this.getSessions();


  },
  methods: {
    getSessions: function () {
      if (this.isObjectEmpty(this.SESSIONS)) {
        this.$store.dispatch("Loader/update_loading", true);
        const link = this.LINKS.find(l => l.rel == 'sessions').href
        // console.log(`> getting sessions --> ${link}`);
        this.$store.dispatch("IAmPublicSpeaker/fetch_sessions", link);

      }

    },
  },
};

</script>

<style lang="scss">
@import '../../scss/theme/t.light';

.card__extras {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  .card__extra__title {
    font-weight: 600;
  }
}

.card__tags {
  border-top: 1px solid $line-color;
  padding-top: 10px;
}


</style>