import config from "../../config";
import Axios from "axios";


const initialState = () => ({
  iAmPartner:null,
  event: {

  },
  links: [],
  actions:{

  },
  isLoading: true,
  currentPage: 'About Techorama'

});
const state = initialState();

const getters = {
  IAMPARTNER: (state) => {
    return state.iAmSpeaker;
  },
  EVENT: (state) => {
    return state.event;
  },
  LINKS: (state) => {
    return state.links;
  },
  ACTIONS: (state) => {
    return state.actions;
  },
  IS_LOADING: (state) => {
    return state.isLoading;

  },

  CURRENT_PAGE: (state) => {
    return state.currentPage;

  },


};

const actions = {

  fetch_iAmPartner: (context, payload) => {
    // console.log("> store: getting i am public speaker");
    Axios.get(`${config.API_URL}IAmSpeaker/${payload}`)
      .then((response) => {
        context.commit("SET_IAMSPEAKER", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_actions: (context, payload) => {
    // console.log(payload);
    Axios.get(payload).then((response) => {
      context.commit("SET_ACTIONS", response.data.data);
    });
  },
  complete_action: (context, payload) => {
    // console.log(payload);
  
      context.commit("COMPLETE_ACTION",payload);

  },
    update_action: (context, payload) => {
    // console.log(payload);
  
      context.commit("UPDATE_ACTION",payload);

  },
  update_loading: (context, payload) => {
    context.commit("SET_ISLOADING",payload);

  },

  update_current_page: (context, payload) => {
    context.commit("SET_CURRENT_PAGE",payload);

  },
};

const mutations = {
  SET_IAMPARTNER: (state, data) => {
    state.iAmSpeaker = data.partner;
    state.event = data.event;
    state.links = data.links;
    state.actions = data.actions;
    state.isLoading = false;
  },
  SET_EVENT_CACHE: (state, data) => {
    state.event = data.data;
    state.isLoading = false;
  },
  SET_ACTIONS: (state, data) => {
    state.actions = data;
    state.isLoading = false;
  },
  COMPLETE_ACTION: (state, data) => {
    const objWithIdIndex = state.actions.openActions.findIndex((obj) => obj.actionId === data.actionId);
    if (objWithIdIndex > -1) {
    
      
      state.actions.openActions.splice(objWithIdIndex, 1);
      state.actions.completedActions.push(data);
      }
 },
 UPDATE_ACTION: (state, data) => {
    const objWithIdIndex = state.actions.completedActions.findIndex((obj) => obj.actionId === data.actionId);
    
    if (objWithIdIndex > -1) {
      state.actions.completedActions.splice(objWithIdIndex, 1);
      state.actions.completedActions.push(data);
      }
 },
 SET_ISLOADING: (state, data) => {
    state.isLoading = data;
  },
  SET_CURRENT_PAGE: (state, title) => {
    state.currentPage = title;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

