const initialState = () => ({
    isLoading: true,
});

const state = initialState();

const getters = {
    IS_LOADING: (state) => {
      return state.isLoading;
  
    },
};

const actions = {
    update_loading: (context, payload) => {
      context.commit("SET_ISLOADING",payload);
  
    },
};

const mutations = {
   SET_ISLOADING: (state, data) => {
      state.isLoading = data;
    },
  };
  
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  