<template>
    <div class="welcome">
        <section class="hero is-halfheight">
            <div class="hero-body">
                <figure>
                    <img src="@/assets/logo_blue.png" title="speakerflow" alt="speakerflow logo">
                    <figcaption>
                        <a href="mailto:mail@speakerflow.net">mail@speakerflow.net</a> 
                    </figcaption>
                </figure>
                <br/>

            </div>
        </section>


    </div>
</template>
<style lang="scss">
.welcome {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.hero-body {
    width: 600px;
    display: unset;
    text-align: center;
}
</style>
<script>
import { mapMutations } from 'vuex';
export default {
  name: 'HelloWorld',
  data() {
    return {
      account: undefined,
      containers: [],
    };
  },
  created() {
    this.$emitter.on('signin', () => {
      console.log("sign in happened");
    })
  },
  methods: {
    ...mapMutations(['setAccessToken']),

  },
};
</script>