import config from "../../config";
import Axios from "axios";

const initialState = () => ({
  flows: {
    all: [],
  },
  actions: {
    all: [],
  },
  flow: {},
  action: {},
  pages: {
    currentPage: 1,
    totalPages: 1,
    totalCount: 3,
    nextPage: null,
    previousPage: null,
    hasPrevious: null,
    hasNext: false,
  },
});
const state = initialState();

const getters = {
  FLOWS: (state) => {
    return state.flows.all;
  },
  ACTIONS: (state) => {
    return state.actions.all;
  },
  ACTION: (state) => {
    return state.action;
  },
  FLOW: (state) => {
    return state.flow;
  },
};

const actions = {
  // GET
  fetch_flows: async (context, payload) => {
    console.log("getting flows");
    return Axios.get(
      `${config.API_URL}events/${payload.eventId}/flows?pageSize=10&pageNumber=${payload.page}`
    )
      .then((response) => {
        context.commit("SET_FLOWS", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  set_flow: async (context, payload) => {
    console.log("setting flow");
    if (payload != null) {
      context.commit("SET_FLOW", payload.flow);
    }
  },
  set_action: async (context, payload) => {
    console.log("setting action");
    if (payload != null) {
      context.commit("SET_ACTION", payload.action);
    }
  },
  update_action: async (context, payload) => {
    console.log("deleting event");
    const l = payload.action.links.find((l) => l.rel == "update-action").href;
    const a = {
      action: {
        id: payload.action.id,
        title: payload.action.title,
        description: payload.action.description,
        value: payload.action.value,
        order: payload.action.order,
      },
    };
    return Axios.put(l, a)
      .then((response) => {
        console.log(response);
        context.commit("UPDATE_ACTION");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  activate_action: async (context, payload) => {
    console.log("deleting event");
    const flow = context.getters.FLOWS.find((element) => element.id == payload.flowId);
    const found = flow.actions.find((element) => element.id == payload.id);
    const l = found.links.find((l) => l.rel == "activate-action").href;
    const a = {
      "flowId": payload.flowId,
      "flowActionId": payload.id,
      "isActive": payload.isActive
    };
    return Axios.put(l, a)
      .then((response) => {
        console.log(response);
        context.commit("UPDATE_ACTION", response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

const mutations = {
  // SET
  SET_FLOWS: (state, data) => {
    state.flows.all = data.flows;
    state.pages = data.pages;
  },
  SET_FLOW: (state, data) => {
    state.flow = data;
  },
  SET_ACTION: (state, data) => {
    state.action = data;
  },
  UPDATE_ACTION: (state, data) => {
    state.action = data;
    console.log(data);
  },
  ACTIVATE_ACTION: (state, data) => {
    state.action = data;
    console.log(data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
