import config from "../../config";
import Axios from "axios";


const initialState = () => ({
  iAmSpeaker: {
    "publicId": "91ab3c91-25b0-4a80-9512-19444462c5ba",
    "firstName": "Ralph",
    "middleName": null,
    "lastName": "Eckhard",
    "email": "ralph@365dude.nl",
    "phoneNumber": null,
    "linkedInUrl": null,
    "facebookUrl": null,
    "twitterUrl": null,
    "websiteUrl": null,
    "profilePicture": "https://oganizeprod.blob.core.windows.net/speakerimages/13a7f768-439b-4f34-b627-a9a4d79a18e2.jpg",
    "profilePictureSmall": "https://oganizeprod.blob.core.windows.net/speakerthumbnails/thumb-sm-13a7f768-439b-4f34-b627-a9a4d79a18e2.jpg",
    "links": [
      {
        "href": "https://localhost:44305/api/v1/IAmSpeaker/91ab3c91-25b0-4a80-9512-19444462c5ba/events",
        "rel": "my_events",
        "method": "GET"
      },
      {
        "href": "https://localhost:44305/api/v1/IAmSpeaker/91ab3c91-25b0-4a80-9512-19444462c5ba",
        "rel": "self",
        "method": "GET"
      }
    ]
  },
  event: {

  }

});
const state = initialState();

const getters = {
  IAMSPEAKER: (state) => {
    return state.iAmSpeaker;
  },
  EVENT: (state) => {
    return state.event;
},
};

const actions = {
  ensure_event: async (context, payload) => {
    if(!context.getters.EVENT || !context.getters.EVENT.publicId){
      await context.dispatch('fetch_event_by_slug', payload);
    }
  },
  set_current_event: (context, payload)=>{
    context.commit("SET_EVENT_CACHE", payload);
  },
  set_iAmSpeaker: (context, payload) => {
    context.commit("SET_IAMSPEAKER_CACHE", payload);
  },
  fetch_event_by_slug: async (context, payload) => {
    console.log("getting full event details");
    return Axios.get(`${config.API_URL}IAmSpeaker/${payload.speakerId}/events/slug/${payload.eventname}`)
      .then((response) => {
        context.commit("SET_EVENT_CACHE", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

const mutations = {
  SET_IAMSPEAKER_CACHE: (state, data) => {
    state.iAmSpeaker = data;
  },
  SET_EVENT_CACHE: (state, data) => {
    state.event = data.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

