<template>
    <div class="c-action" v-bind:class="getPriorityClass()">
      <div class="c-action__header">
        <div class="c-action__title">
          <h1>⚡️ {{ actionId }} {{ actionTitle }}</h1>
        </div>
        <div class="c-action__extras">
        
          <span class="tag is-normal"  v-bind:class="getPriorityClass()">
            {{ actionPriority }}
          </span>
         
           
        </div>
      </div>
      <div class="c-action__description">
        <span>{{ actionDescription }}</span>
      </div>
      <div class="c-action__text">
        
      </div>
    </div>
  </template>
  <script>
  
  export default {
    props: ['action-id', 'action-title', 'action-description', 'action-status', 'action-priority', 'action-isIncluded', 'action-isSticky'],
    components: {
      
    },
    data: function () {
      return {
        isActionDetailsModalOpen: true,
      }
    },
    methods: {
      getPriorityClass() {
        if (this.actionPriority == "LOW") return 'is-primary';
        if (this.actionPriority == "MEDIUM") return 'is-primary';
        if (this.actionPriority == "HIGH") return 'is-warning';
        if (this.actionPriority == "HIGHEST") return 'is-danger';
      },
    getInfo(){
        return this.actionIsIncluded;
      }
    }
  }
  </script>
