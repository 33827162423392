<template>
    <section class="panel is-danger"
        v-if="ISLOADING == false && (HOTEL_RESERVATION_INFO == null || !HOTEL_RESERVATION_INFO.reservationId)">
        <h2 class="panel-heading">🛌 Reservation</h2>
        <div class="panel-block">
            <div class="panel-block__header" v-if="reservation == null">
                <h3><strong>No Reservation</strong> have been been for you</h3>
            </div>
            <div class="panel-block__warning">
                <p>
                    No reservation has been made for you. This could be because:
                <ul>
                    <li>You live in the neighbourhood of the location of the event</li>
                    <li>You told us you don't need a hotel reservation</li>
                    <li>You booked a hotel yourself</li>
                </ul>
                </p>
                <p>
                    If we made a mistake, sorry but we will fix it for you.
                    Please contact us via <a href="mailto:board@techorama.nl">board@techorama.nl</a></p>
            </div>



        </div>

    </section>
    <div class="columns">

        <div class="column">

            <section class="panel" v-if="HOTEL_RESERVATION_INFO != null && HOTEL_RESERVATION_INFO.reservationId">
                <h2 class="panel-heading">🛌 Reservation</h2>
                <div class="panel-block">
                    <div class="panel-block__header">
                        <h3>Reservation in {{ HOTEL_RESERVATION_INFO.hotel.name }}</h3>
                        <span class="tag is-success"
                            :class="{ 'is-danger': HOTEL_RESERVATION_INFO.status == 'Pending' }">{{
            HOTEL_RESERVATION_INFO.status }}</span>
                    </div>
                    <div class="info-card">

                        <div class="info-card-content">

                            <div class="info-card-content__block">
                                <div class="info-card-content__block-item">
                                    <strong class="wide">➡️ check-in date</strong>
                                    <span>{{ $filters.humanDate(HOTEL_RESERVATION_INFO.checkInDate) }}</span>
                                </div>
                                <div class="info-card-content__block-item">
                                    <strong class="wide">⬅️ check-out date</strong>
                                    <span>{{ $filters.humanDate(HOTEL_RESERVATION_INFO.checkOutDate) }}</span>
                                </div>
                                <div class="info-card-content__block-item">
                                    <strong class="wide">🔢 rooms</strong>
                                    <span>{{ HOTEL_RESERVATION_INFO.numberOfRooms }}</span>
                                </div>
                                <div class="info-card-content__block-item">
                                    <strong class="wide">🔢 adults</strong>
                                    <span>{{ HOTEL_RESERVATION_INFO.numberOfAdults }}</span>
                                </div>
                                <div v-if="HOTEL_RESERVATION_INFO.numberOfChildren > 0" class="info-card-content__block-item">
                                    <strong class="wide">🔢 children</strong>
                                    <span>{{HOTEL_RESERVATION_INFO.numberOfChildren }}</span>
                                </div>
                                <div class="info-card-content__block-item" v-if="HOTEL_RESERVATION_INFO.numberOfNightsCoveredBySpeaker > 0">
                                    <strong class="wide">💵 nights by Techorama</strong>
                                    <span>{{ HOTEL_RESERVATION_INFO.numberOfNightsCoveredByClient }}</span>
                                </div>
                                <div class="info-card-content__block-item" v-if="HOTEL_RESERVATION_INFO.numberOfNightsCoveredBySpeaker > 0">
                                    <strong class="wide">💵 nights by Speaker</strong>
                                    <span>{{ HOTEL_RESERVATION_INFO.numberOfNightsCoveredBySpeaker }}</span>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


            </section>

            <section class="panel"
                v-if="selectedAction != null && HOTEL_RESERVATION_INFO != null && HOTEL_RESERVATION_INFO.reservationId">
                <h2 class="panel-heading">⚡ Is your reservation ok?</h2>
                <div class="panel-block">

                    <div class="action__body">
                        <div class="action-info__response">
                            <label>Extra comments. Use this field when your reservation is not OK!</label>
                            <textarea class="textarea" placeholder="extra info" v-model="response"></textarea>
                        </div>
                    </div>
                    <div class="action__buttons">
                        <button class="button is-success " @click="completeAction(selectedAction.actionId, true)"><i
                                class="fas fa-check"></i> &nbsp;
                            OK</button>
                        <button class="button is-danger " @click="completeAction(selectedAction.actionId, false)"><i
                                class="fas fa-ban"></i> &nbsp;
                            NOT OK</button>
                    </div>
                </div>


            </section>



        </div>
        <div class="column is-one-quarter" v-if="HOTEL_RESERVATION_INFO != NULL && HOTEL_RESERVATION_INFO.hotel">
            <div class="event__details__card card">
                <div class="card-image">
                    <figure class="image is-small">
                        <img v-bind:src="HOTEL_RESERVATION_INFO.hotel.imageUrl"
                            v-bind:alt="HOTEL_RESERVATION_INFO.name">
                    </figure>
                </div>
                <div class="card-content">
                    <div class="media">

                        <div class="media-content">
                            <p class="media-content__title">{{ HOTEL_RESERVATION_INFO.hotel.name }}</p>
                            <p class="media-content__text">📌 {{ HOTEL_RESERVATION_INFO.hotel.city }} {{
            HOTEL_RESERVATION_INFO.hotel.country }}</p>
                            <p class="media-content__text">📌 {{ HOTEL_RESERVATION_INFO.hotel.address }}</p>
                            <p class="media-content__text">✉️ {{ HOTEL_RESERVATION_INFO.hotel.email }}</p>
                            <p class="media-content__text">☎️ +32 (0)3 235 91 91</p>
                            <p class="media-content__text"><a href="https://www.vandervalkantwerpen.be/"
                                    target="_blank">🔗
                                    go to website...</a></p>
                        </div>
                    </div>

                    <div class="content">

                    </div>
                </div>


            </div>
        </div>


    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Axios from "axios";
export default {
    components: {

    },
    data: function () {
        return {
            isActionNeeded: true,
            selectedAction: null
        }
    },
    computed: {
        ...mapGetters({
            IAMSPEAKER: "IAmPublicSpeaker/IAMSPEAKER",
            LINKS: "IAmPublicSpeaker/LINKS",
            HOTEL_RESERVATION_INFO: "Travel/HOTEL_RESERVATION_INFO",
            ACTIONS: "IAmPublicSpeaker/ACTIONS",
            ISLOADING: "Loader/IS_LOADING",
        }),
    },
    mounted: function () {
        // if(this.ACTIONS)
        // this.selectedAction = this.ACTIONS.openActions.find(a => a.type == "HOTELOK");

        if (this.isObjectEmpty(this.HOTEL_RESERVATION_INFO)) {
            this.$store.dispatch("Loader/update_loading", true);
            this.getHotelInfo();
        }
    },
    methods: {
        getHotelInfo: function () {
            const link = this.LINKS.find(l => l.rel == 'hotel-info').href
            //console.log(`getting hotel info --> ${link}`);
            this.$store.dispatch("Travel/fetch_hotel_info", link);
        },
        completeAction(actionId, isOk) {
            //console.log(actionId);
            const link = this.selectedAction.links.find(l => l.rel == 'complete_action').href;
            Axios.post(link, {
                response: {
                    isOk: isOk,
                    message: this.response
                }
            }).then((response) => {
                ///console.log("action completed");
                this.$store.dispatch("IAmPublicSpeaker/complete_action", response.data.data);
                this.selectedAction = null;
            });
        },
    },
}
</script>

<style lang="scss">
@import '../../scss/theme/t.light';

.info-card {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.info-card .info-card-content__block .info-card-content__block-item strong.wide{
    width: 220px;
}

.panel-block ul {
    list-style-type: disc;
    margin: 5px 30px;
}
</style>