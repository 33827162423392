import config from "../../config";
import Axios from "axios";


const initialState = () => ({
    speakers: {
        all: []
    },
    speaker: {
      
    },
    speaker_reservation: null,
    travel_information: null,

    unknownSpeaker: {

    },
    pages: {
      currentPage: 1,
      totalPages: 1,
      totalCount: 3,
      nextPage: null,
      previousPage: null,
      hasPrevious: null,
      hasNext: false
    }
});
const state = initialState();

const getters = {
    SPEAKERS: (state) => {
        return state.speakers.all;
    },
    SPEAKER: (state) => {
      return state.speaker;
    },
    SPEAKER_RESERVATION: (state) => {
      return state.speaker_reservation;
    },
    SPEAKER_LINKS: (state) => {
    return state.speaker.links;
    },
    SPEAKER_RESERVATION_LINK: (state) => {
      let l = state.speaker.links.find(l => l.rel == "hotel_reservation");
      if(l == null) return null;
      return l.href;
    },
    UNKNOWNSPEAKER: (state) => {
    return state.unknownSpeaker;
    },
    PAGINATION: (state) => {
      return state.pages;
    },
    HOTEL_RESERVATION: (state) =>{
      if(state.travel_information == null) return null;
      return state.travel_information.hotel;
    },
    FLIGHT_INFORMATION: (state) => {
      console.log("getting flight info");
      if(state.travel_information == null) return null;
      return state.travel_information.flight;
    }
}; 

const actions = {
  ensure_speaker: async (context, payload) => {
    console.log("ensuring speaker");
    if(!context.getters.SPEAKER || !context.getters.SPEAKER.publicId){
      await context.dispatch('fetch_event_by_slug', payload.eventName);
      return await context.dispatch("fetch_speaker", payload).then();
    }
  },
   // GET
   fetch_speakers: async (context, payload) => {
    console.log("fetching speakers");
    let url = `${config.API_URL}events/${payload.eventId}/speakers?pageSize=15&pageNumber=${payload.page}`;
    if(payload.q){
      url += `&name=${payload.q}`;
    }

    return Axios.get(url)
      .then((response) => {
        context.commit("SET_SPEAKERS", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  remove_speaker_from_speakers: async (context, payload) => {
    context.commit("REMOVE_SPEAKER", payload);
  },

  fetch_unmappedspeakers: async (context, payload) => {
    console.log("speakers.store.js - GET unmapped speakers:" + payload);
    console.log(config);
    console.log(`${config.API_URL}events/02D582AE-92AD-4DAD-A634-86EC14481622/speakers`);
    return Axios.get(`${config.API_URL}events/d4f81891-3315-413a-bf21-6b39dfd5c9d6/speakers/indoubt?pageNumber=${payload.page}`)
      .then((response) => {
        context.commit("SET_UNMAPPEDSPEAKERS", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // GET
  fetch_speaker: async (context, payload) => {
    console.log("speakers.store.js - GET speaker:" + payload);
    return Axios.get(`${config.API_URL}events/${payload.eventId}/speakers/${payload.speakerId}`)
      .then((response) => {
        context.commit("SET_SPEAKER", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // GET
  fetch_travel_information: async (context, payload) => {
    console.log("speakers.store.js - GET speaker:" + payload);
    return Axios.get(payload)
      .then((response) => {
        context.commit("SET_TRAVELINFO", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },


  fetch_speaker_reservation: async (context, payload) => {
    if(payload == null){
      context.commit("SET_SPEAKER_RESERVATION", null);
    }
    console.log("speakers.store.js - GET speaker reservation:" + payload);
    return Axios.get(payload)
      .then((response) => {
        context.commit("SET_SPEAKER_RESERVATION", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  clear_speaker_data: async(context) => {

    context.commit("CLEAR_DATA");
  },

  set_speaker_reservation: async (context, payload) => {
    if(payload != null){
      context.commit("SET_SPEAKER_RESERVATION", payload);
    }
  },
  speaker_updated: async(context, payload) => {
    context.commit("SET_SPEAKER", payload.data);
  },

  fetch_unknownspeaker: async (context, payload) => {
    console.log("speakers.store.js - GET uknownspeaker:" + payload);
    return Axios.get(payload.link)
      .then((response) => {
        context.commit("SET_UNKNOWNSPEAKER", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  post_unmappedspeaker: async(context, payload) => {
    console.log("posting speaker");
    return Axios.post(payload.link, payload.newSpeaker)
      .then((response) => {
        context.commit("REMOVE_UNMAPPEDSPEAKER", response.data.data);
      })
      .catch(() => {
        //handleFailed("Failed to create an sole.", error);
      });

  },
     // POST
     map_unmappedspeaker: async(context, payload) => {
      console.log("mapping speaker");
      return Axios.put(payload.link, payload.newSpeaker)
        .then((response) => {
          context.commit("REMOVE_UNMAPPEDSPEAKER", response.data.data);
        })
        .catch(() => {
          //handleFailed("Failed to create an sole.", error);
        });
  
    }

};

const mutations = {
     // SET
  SET_SPEAKERS: (state, data) => {
    state.speakers.all = data.speakers;
    state.pages = data.pages;
  },
  SET_UNMAPPEDSPEAKERS: (state, data) => {
    state.unmappedspeakers.all = data.speakers;
    state.pages = data.pages;
  },
  SET_SPEAKER: (state, data) => {
    state.speaker = data;
  },
  SET_TRAVELINFO: (state, data) => {
    state.travel_information = data;
  },
  SET_SPEAKER_RESERVATION: (state, data) => {
    state.speaker_reservation = data;
  },
  SET_UNKNOWNSPEAKER: (state, data) => {
    state.unknownSpeaker = data;
  }
  ,
  CLEAR_DATA: (state) => {
    state.speaker_reservation = null;
    state.travel_information = null;
  }
  ,
  REMOVE_UNMAPPEDSPEAKER: (state, data) => {
    state.unmappedspeakers.all.splice(
      state.unmappedspeakers.all.findIndex((item) => item.publicId === data.publicId),
      1
    );
  },
  REMOVE_SPEAKER: (state, data) => {
    console.log("removing speaker");
    state.speakers.all.splice(
      state.speakers.all.findIndex((item) => item.id === data.id),
      1
    );
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  