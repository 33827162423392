<template>
    <div class="columns c-public-about">
    
        <div class="column">
            <p v-html="EVENT.speakerInfo"></p>
        </div>
        <div class="column is-one-quarter">
            <div class="event__details__card card">
                <div class="card-image">
                    <figure class="image is-small">
                        <img v-bind:src="EVENT.eventPicture" v-bind:alt="EVENT.title">
                    </figure>
                </div>
                <div class="card-content">
                    <div class="media">

                        <div class="media-content">
                            <p class="title is-4">{{ EVENT.title }}</p>
                            <p class="subtitle is-6">{{ EVENT.location }}</p>
                        </div>
                    </div>
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-4">start date</p>
                            <p class="subtitle is-6">
                                <time datetime="2016-1-1">{{ $filters.humanDate(EVENT.startDate) }}</time> - <time
                                    datetime="2016-1-1">{{ $filters.humanDate(EVENT.endDate) }}</time>
                            </p>
                        </div>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">Contact</p>
                        <p class="subtitle is-6">
                            ✉️ <a :href="`mailto:${EVENT.email}`">{{ EVENT.email }}</a>
                            <br/>
                            🌐 <a target="_blank" :href="`${EVENT.website}`">{{ EVENT.website }}</a>
                        </p>
                       
                    </div>

                </div>
            </div>


        </div>


    </div>
</template>
<script>



import { mapGetters } from "vuex";

export default {
    name: "PublicAboutComponent",
    computed: {
        ...mapGetters({
            EVENT: "IAmPublicSpeaker/EVENT"
        }),
    }
};
</script>
