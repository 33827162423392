<template>
  <header class="c-public-header">
    <welcome-component></welcome-component>
  </header>
  <div>
   
    <main class="e-main">

      <div class="columns">
        <div class="column">
          <reservations-component></reservations-component>
        </div>
       
     
      </div>
    </main>

  </div>
</template>
<script>

import WelcomeComponent from "@/components/WelcomePublicHotelComponent.vue";
import ReservationsComponent from "@/components/public-hotel/ReservationsComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "HotelsPage",
  components: {
    WelcomeComponent,
    ReservationsComponent
  },
  computed: {
    ...mapGetters({
      HOTEL: "MyHotel/HOTEL",
      RESERVATIONS: "MyHotel/RESERVATIONS",
      
    }),
  },
  mounted: function () {

    this.getHotelDetails();

  },
  methods: {
    getHotelDetails: function () {
      console.log("getting hotel details");
      //if (this.HOTEL.publicId) {
        console.log("we know everything about the event");
     
      //}
      // else {
      //   console.log("we don't know shit about the event");
      //   this.$store.dispatch("Hotels/fetch_hotel_by_slug", this.$route.params.eventname);
      // }

    }
  }
};
</script>