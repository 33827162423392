
const initialState = () => ({
    session: {
      
    },
});
const state = initialState();

const getters = {
    SESSION: (state) => {
        return state.session;
    },
    
}; 

const actions = {
  
  cache_session: async(context, payload) => {
    context.commit("SET_SESSION", payload);
  }
};

const mutations = {
     // SET
  SET_SESSION: (state, data) => {
    state.session = data;
  },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  