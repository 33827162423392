import config from "../../config";
import Axios from "axios";


const initialState = () => ({
    actions: {
        all: [],
        links: []
    },
    action: {

    }
});
const state = initialState();

const getters = {
    ACTIONS: (state) => {
        return state.actions.all;
    },
    ACTIONSLINKS: (state) => {
      return state.actions.links;
    },
    ACTIONSPOSTLINK: (state) => {
      console.log("getting post linke");
      let l = state.actions.links.find(l => l.method == "POST");
      return l.href;

    },
    ACTION: (state) => {
      return state.action;
  }
}; 

const actions = {
   // GET
   fetch_actions: async (context, payload) => {
    console.log("actions.store.js - GET actions:" + payload);
    return Axios.get(`${config.API_URL}events/${payload.eventId}/actions`)
      .then((response) => {
        context.commit("SET_ACTIONS", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // GET
  fetch_action: async (context, payload) => {
    console.log("actions.store.js - GET action:" + payload);
    return Axios.get(payload.href)
      .then((response) => {
        context.commit("SET_ACTION", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // POST
  post_action: async(context, payload) => {
    console.log("actions.store.js - GET action:" + payload);
    return Axios.post(context.getters.ACTIONSPOSTLINK, payload.action)
    .then((response) => {
      //handleSuccess();
    
      context.commit("ADD_ACTION", response);
    })
    .catch(() => {
      //handleFailed("Failed to create an sole.", error);
    });
},

};

const mutations = {
     // SET
  SET_ACTIONS: (state, data) => {
    state.actions.all = data.actions;
    state.actions.links = data.links;
  },
  SET_ACTION: (state, data) => {
    state.action = data;
  }
  ,
  ADD_ACTION: (state, data) => {
    console.log("new action");
    state.actions.all.push(data.data.data);
  }
  
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  