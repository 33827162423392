import Axios from "axios";
import config from "../../config";

const initialState = () => ({
    current_event: {
        event: {},
        links: []
    }
});
const state = initialState();

const getters = {
  EVENT: (state) => {
      return state.current_event.event;
  },
  EVENT_DELETE_LINK: (state) => {
    let l = state.current_event.links.find(l => l.method == "DELETE");
    return l.href;
  },
  EVENT_SESSIONS_LINK: (state) => {
    let l = state.current_event.links.find(l => l.rel == "event_sessions");
    return l.href;
  },
  EVENT_LINKS: (state) => {
    
    return state.current_event.links;
  }
}; 

const actions = {
  ensure_event: async (context, payload) => {
    if(!context.getters.EVENT || !context.getters.EVENT.publicId){
      await context.dispatch('fetch_event_by_slug', payload);
    }
  },
  set_current_event: (context, payload)=>{
    context.commit("SET_EVENT_CACHE", payload);
  },
  fetch_event: async (context, payload) => {
    console.log("getting full event details");
    return Axios.get(`${config.API_URL}events/${payload}`)
      .then((response) => {
        context.commit("SET_EVENT", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetch_event_by_slug: async (context, payload) => {
    console.log("getting full event details");
    return Axios.get(`${config.API_URL}events/slug/${payload}`)
      .then((response) => {
        context.commit("SET_EVENT", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  delete_event: async (context) => {
    console.log("deleting event");
    return Axios.delete(context.getters.EVENT_DELETE_LINK)
      .then((response) => {
        console.log(response);
        context.commit("DELETE_EVENT");
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

const mutations = {
SET_EVENT_CACHE: (state, data) => {
  state.current_event.event = data;
},
 SET_EVENT: (state, data) => {
    console.log("event details");
    state.current_event.event = data.data;
    if(data.data.links) state.current_event.links = data.data.links;
  },
  DELETE_EVENT: (state) => {
    state.current_event = null;
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  