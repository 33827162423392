<template>

  <div class="c-welcome">
    <h1 class="c-welcome__person">{{FriendlyText}} <span v-if="auth.account">{{ auth.account.name }}!</span></h1>
    <span v-if="!this.isObjectEmpty(EVENT)" class="c-welcome__description">Your information and <strong>actions</strong> for {{ EVENT.title }}</span>
  </div>


</template>

<script>
import { mapGetters } from "vuex";
import { useAuth } from '@/auth';
export default {
  setup(){
       const auth = useAuth();

        return {
            auth
        }
    },
  computed: {
    ...mapGetters({
      EVENT: "Events/EVENT"
    }),
    FriendlyText() {
      var myDate = new Date();
      var hrs = myDate.getHours();

      var friendlyText;

      if (hrs < 12)
      friendlyText = 'Good Morning';
      else if (hrs >= 12 && hrs <= 17)
      friendlyText = 'Good Afternoon';
      else if (hrs >= 17 && hrs <= 24)
      friendlyText = 'Good Evening';

      return friendlyText;
    }
  },
}

</script>